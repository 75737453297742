import React, { useEffect } from 'react';
import './styles.scss';
import Image from '../Image';
import { accordionData } from '../../data/principles/accordionData';

const Accordion = () => {
  useEffect(() => {
    const acc = document.getElementsByClassName('accordion');
    let i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active');

        const panel = this.nextElementSibling;
        if (panel.style.display === 'block') {
          panel.style.display = 'none';
        } else {
          panel.style.display = 'block';
        }
      });
    }
  });

  return (
    <>
      {accordionData.map(
        ({ title, content }, index) => (
          (index = index + 1),
          (
            <div className="accordion-container">
              <button class={`accordion accordion-${index}`}>
                {title}
                {index === 1 && <Image filename="arrow-white.svg" />}
                {index === 2 && <Image filename="arrow-white.svg" />}
                {index === 3 && <Image filename="arrow-black.svg" />}
                {index === 4 && <Image filename="arrow-black.svg" />}
                {index === 5 && <Image filename="arrow-black.svg" />}
                {index === 6 && <Image filename="arrow-black.svg" />}
                {index === 7 && <Image filename="arrow-white.svg" />}
              </button>
              <div class={`panel panel-${index}`}>
                <p>{content}</p>
              </div>
            </div>
          )
        )
      )}
    </>
  );
};

export default Accordion;
