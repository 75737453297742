import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container } from '../../components/Grid';
import Hero from '../../components/Hero';
import Masonry from '../../components/Masonry';
import Accordion from '../../components/Accordion';

const CooperativePage = () => (
  <Layout title="Cooperative">
    <Hero
      className="hero--cooperative"
      iconFile="img-what-is-coop-illustration.png"
      banner={true}
      heroBanner="What is a Cooperative?"
    >
      <h2>Capitalizing on Collaboration</h2>
      <p>
        A <strong>cooperative</strong> is a business organization that is owned
        and governed by the members who use its products, supplies, or services,
        joining together likeminded individuals or organizations to achieve a
        common goal.
      </p>
      <p>
        An <strong>agricultural co-op</strong> pools together the resources of
        its farmer members with the expertise of ag professionals working in
        specific areas to help maximize returns to the members so their farms
        and ranches can stay bountiful for years to come, without exhausting
        resources.
      </p>
    </Hero>
    <Section>
      <Container className="cooperative__container">
        <h2 className="home__principles">The 7 Cooperative Principles</h2>
        <Masonry />
        <Accordion />
      </Container>
    </Section>
  </Layout>
);

export default CooperativePage;
