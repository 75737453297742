export const accordionData = [
  {
    title: 'Voluntary & Open Membership',
    content: `Membership in a cooperative is open to anyone without gender, social, racial, or religious discrimination. As a member, you can join, do business with, or leave the cooperative at any time.`,
  },
  {
    title: 'Democratic Owner Control',
    content: `One member, one vote. As a member of a cooperative, your voice will be heard. All members have an equal voice in making policies and electing representatives.`,
  },
  {
    title: 'Member Economic Participation',
    content: `Members contribute to and equally control the capital or the profit of the cooperative. The profit of the cooperative is returned to the members, reinvested in the cooperative, or used to provide owner services.`,
  },
  {
    title: 'Autonomy & Independence',
    content: `Cooperatives are self-governed organizations and are controlled by their members. Only members can make changes to the cooperative.`,
  },
  {
    title: 'Education, Training & Information',
    content: `Cooperatives provide technical training and education to their members so they can, in turn contribute to the development and growth of their cooperatives. Cooperatives also inform the general public about the nature and benefits of cooperation.`,
  },
  {
    title: 'Cooperation Among Cooperatives',
    content: `Cooperatives serve their members most effectively and strengthen the cooperative movement by working together through local, regional, national, and international structures. Cooperatives work with other cooperatives.`,
  },
  {
    title: 'Concern for Community',
    content: `While focusing on member needs, cooperatives work for the sustainable development of their communities through policies approved by their members. You can do something for the community even as you keep succeeding.`,
  },
];
