import React, { useEffect } from 'react';
import './styles.scss';
import { accordionData } from '../../data/principles/accordionData';

const Masonry = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const Isotope = require('isotope-layout/js/isotope');

      const iso = new Isotope('.masonry', {
        itemSelector: '.masonry-item',
        percentPosition: true,
        masonry: {
          columnWidth: '.masonry-sizer',
        },
      });
    }
  });

  return (
    <>
      <div className="masonry">
        <h3 className="masonry-title">The 7 Cooperative Principles</h3>
        <div class="masonry-sizer"></div>
        {accordionData.map(({ title, content }, index) => (
          <>
            <div class={`masonry-item masonry-item${index + 1}`}>
              <span>{index + 1}</span>
              <h3>{title}</h3>
              <p className="masonry-copy">{content}</p>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default Masonry;
